import { Card, Layout } from 'antd';
import type { DictData } from 'egenie-common';
import { objToDict } from 'egenie-common';
import type { NormalProgramme, MainSubStructureModel, BaseData } from 'egenie-utils';
import { request, MainSubStructure, NormalProgrammeComponent, ExportStore, ExportModal } from 'egenie-utils';
import React from 'react';
import { formatWarehouseChoice, getAllVendor, getOwner, getWarehouse, getWarehouseArea } from '../../utils';
import styles from './index.less';
import { mainSubStructureModel } from './mainSubStructureModel';
import { normalProgramme } from './normalProgramme';
import { ParamsSettingModal, ParamsSettingModalStore } from './paramsSettingModal';

export class Store {
  constructor() {
    this.mainSubStructureModel = mainSubStructureModel(this);

    this.normalProgramme = normalProgramme(this);

    this.mainSubStructureModel.getFilterParams = () => this.normalProgramme.filterItems.params;

    // 仓库
    getWarehouse()
      .then((info) => {
        this.normalProgramme.filterItems.updateFilterItem([
          {
            field: 'warehouse_id-4-6',
            data: info,
            value: formatWarehouseChoice(info, 'warehouse_id-4-6'),
          },
        ]);
        this.getDataAfterWarehouse(formatWarehouseChoice(info, 'warehouse_id-4-6'));
      });

    // 库区
    getWarehouseArea()
      .then((info) => this.normalProgramme.filterItems.addDict({ 'warehouse_area_id-4-6': info }));

    // 货主
    getOwner()
      .then((data) => this.normalProgramme.filterItems.addDict({ 'owner_id-4-6': data }));

    // 供应商
    getAllVendor()
      .then((data) => this.normalProgramme.filterItems.addDict({ 'vendor_tenant_id-4-6': data }));

    request<BaseData<DictData>>({ url: '/api/cloud/wms/rest/warehouse/cloud/query/owner/stock/type' })
      .then((info) => this.normalProgramme.filterItems.addDict({ ownerStockTypeMapEnum: objToDict(info.data) }));
  }

  public getDataAfterWarehouse = (warehouseId: string): void => {
    // 货主
    getOwner({ warehouseId })
      .then((info) => this.normalProgramme.filterItems.addDict({ 'owner_id-4-6': info }));
  };

  public mainSubStructureModel: MainSubStructureModel;

  public paramsSettingModalStore: ParamsSettingModalStore = new ParamsSettingModalStore();

  public normalProgramme: NormalProgramme;

  public exportStore: ExportStore = new ExportStore({ parent: null });
}

export default function() {
  const store = React.useMemo(() => new Store(), []);
  const {
    normalProgramme,
    mainSubStructureModel,
    exportStore,
    paramsSettingModalStore,
  } = store;
  return (
    <>
      <Layout className={styles.container}>
        <Layout.Content>
          <Card size="small">
            <NormalProgrammeComponent store={normalProgramme}/>
          </Card>
          <div className={styles.tableWrapper}>
            <MainSubStructure store={mainSubStructureModel}/>
          </div>
        </Layout.Content>
      </Layout>
      <ExportModal store={exportStore}/>
      <ParamsSettingModal paramsSettingModalStore={paramsSettingModalStore}/>
    </>
  );
}
