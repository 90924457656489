import type { FormInstance } from 'antd';
import { Form, Modal, message, InputNumber, Row } from 'antd';
import type { BaseData } from 'egenie-common';
import { request } from 'egenie-utils';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';
import { formatNumber } from 'egenie-common';

interface Params {
  day?: number;
  saleNumberThreshold: number;
}

export class ParamsSettingModalStore {
  public formRef = React.createRef<FormInstance>();

  @observable public isLoading = false;

  @observable public visible = false;

  @action public handleOpen = () => {
    this.visible = true;
    request<BaseData<Params>>({ url: '/api/cloud/wms/rest/warehouse/cloud/unsalable/param' })
      .then((info) => {
        this.formRef.current?.setFieldsValue({
          day: formatNumber(info?.data?.day) || undefined,
          saleNumberThreshold: formatNumber(info?.data?.saleNumberThreshold) || undefined,
        });
      });
  };

  @action public handleClose = () => {
    this.visible = false;
  };

  @action public handleFinish = (data: Params) => {
    this.isLoading = true;
    request({
      url: '/api/cloud/wms/rest/warehouse/cloud/unsalable/param/modify',
      method: 'POST',
      data,
    })
      .then(() => {
        message.success('保存成功');
        this.handleClose();
      })
      .finally(() => this.isLoading = false);
  };
}

export const ParamsSettingModal = observer(({
  paramsSettingModalStore: {
    formRef,
    visible,
    handleClose,
    isLoading,
    handleFinish,
  },
}: { paramsSettingModalStore: ParamsSettingModalStore; }) => {
  return (
    <Modal
      centered
      destroyOnClose
      maskClosable={false}
      okButtonProps={{ loading: isLoading }}
      onCancel={handleClose}
      onOk={() => formRef?.current?.submit()}
      title="参数设置"
      visible={visible}
      width={600}
    >
      <Form
        layout="horizontal"
        onFinish={handleFinish}
        ref={formRef}
      >
        <Row align="middle">
          滞销商品定义:&nbsp;
          <Form.Item
            name="day"
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              max={999999}
              min={0}
              placeholder="请输入天数"
              style={{ width: 150 }}
            />
          </Form.Item>
          &nbsp;天内,销量低于&nbsp;
          <Form.Item
            name="saleNumberThreshold"
            style={{ marginBottom: 0 }}
          >
            <InputNumber
              max={999999}
              min={0}
              placeholder="请输入件数"
              style={{ width: 150 }}
            />
          </Form.Item>
          &nbsp;件
        </Row>
      </Form>
    </Modal>
  );
});
