import { Button, Modal, message } from 'antd';
import type { BaseData, PaginationData } from 'egenie-common';
import { destroyAllModal, renderModal, request } from 'egenie-common';
import { ImgFormatter, MainSubStructureModel } from 'egenie-utils';
import React from 'react';
import { ENUM_OWNER } from '../../utils';
import { GoodsExchangeOwnerModal } from './goodsExchangeOwnerModal';
import type { MainItem } from './types';
import type { Store } from './index';

export function mainSubStructureModel(context: Store): MainSubStructureModel {
  return new MainSubStructureModel({
    grid: {
      columns: [
        {
          key: 'warehouse_name',
          name: '仓库',
          width: 150,
        },
        {
          key: 'warehouse_area_name',
          name: '库区',
          width: 150,
        },
        {
          key: 'owner_name',
          name: '货主',
          width: 150,
        },
        {
          key: 'vendor_name',
          name: '供应商',
          width: 150,
        },
        {
          key: 'sku_no',
          name: 'SKU编码',
          width: 250,
          sortable: true,
        },
        {
          key: 'bar_code',
          name: '条形码',
          width: 150,
          sidx: 'sku.bar_code',
          sortable: true,
        },
        {
          key: 'product_no',
          name: '商品编码',
          width: 150,
          sortable: true,
        },
        {
          key: 'product_name',
          name: '商品名称',
          width: 250,
          sidx: 'product.product_name',
          sortable: true,
        },
        {
          key: 'pic',
          name: '图片',
          width: 90,
          formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
        },
        {
          key: 'color_type',
          name: '颜色',
          width: 120,
        },
        {
          key: 'size_type',
          name: '尺码',
          width: 120,
        },
        {
          name: '商品货号',
          sidx: 'sku.seller_outer_no',
          key: 'seller_outer_no',
          width: 120,
          sortable: true,
        },
        {
          name: '成本价',
          key: 'cost_price',
          width: 100,
        },
        {
          name: '实物库存',
          key: 'real_number',
          width: 100,
          sortable: true,
        },
        {
          name: '货值',
          key: 'price',
          width: 80,
        },
        {
          name: '库存类型',
          key: 'stock_type',
          width: 100,
        },
        {
          name: '可销库存',
          key: 'sale_number',
          width: 100,
        },
        {
          name: '缺货数量',
          key: 'out_number',
          width: 100,
        },
        {
          name: 'SKU供应商',
          key: 'sku_vendor_name',
          width: 200,
        },
        {
          name: '是否滞销',
          key: 'unsalableDesc',
          width: 80,
        },
        {
          name: '备货区库存',
          key: 'reserve_stock_num',
          width: 80,
        },
      ].map((info) => ({
        resizable: true,
        sortable: false,
        ...info,
      })),
      rows: [],
      primaryKeyField: 'v_stock_id',
      setColumnsDisplay: true,
      gridIdForColumnConfig: '/egenie-cloud-wms/ownerInventorySearch/index',
      sumColumns: [
        'real_number',
        'price',
      ],
      searchReduce: true,
      searchReduceConfig: [
        {
          name: '总实物库存',
          value: 0,
        },
        {
          name: '总货值',
          value: 0,
        },
      ],
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      pageSize: 50,
    },

    // 主表查询api
    api: {
      onQuery: (params) => {
        const {
          filterParams = {},
          ...rest
        } = params;

        request<BaseData<{ totalNumber: number;totalPrice: number; }>>({
          url: '/api/cloud/wms/rest/warehouse/cloud/query/owner/stock/num/and/price',
          method: 'POST',
          data: filterParams,
          headers: { warehouseId: filterParams?.['warehouse_id-4-6'] || '' },
        })
          .then((info) => {
            context.mainSubStructureModel.gridModel.searchReduceConfig[0].value = info.data.totalNumber || 0;
            context.mainSubStructureModel.gridModel.searchReduceConfig[1].value = info.data.totalPrice || 0;
          });

        return request<PaginationData<MainItem>>({
          url: '/api/cloud/wms/rest/warehouse/cloud/queryOwnerStock',
          method: 'POST',
          data: new URLSearchParams(Object.entries({
            ...rest,
            vo: JSON.stringify(filterParams),
          })),
          headers: { warehouseId: filterParams?.['warehouse_id-4-6'] || '' },
        });
      },
    },
    hiddenSubTable: true,
    pageId: '2673',
    btnExtraRight: (
      <Button
        onClick={() => context.paramsSettingModalStore.handleOpen()}
        type="link"
      >
        参数设置
      </Button>
    ),
    buttons: [
      {
        text: '导出',
        handleClick: () => {
          const ids = Array.from(context.mainSubStructureModel.gridModel.selectedIds)
            .join(',');
          const fileName = '货主库存';
          const exportType = 'cloud_wms_owner_stock';
          const queryParam = {
            ...context.normalProgramme.filterItems.params,
            warehouseId: context.normalProgramme.filterItems.params['warehouse_id-4-6'],
          };
          const queryParamShow = context.normalProgramme.filterItems.translateParams.join(' ');

          if (ids) {
            context.exportStore.onShow(fileName, exportType, ids, queryParam, queryParamShow);
          } else {
            Modal.confirm({
              title: '提示',
              content: '未选择数据将导出全部数据?',
              onOk: () => {
                context.exportStore.onShow(fileName, exportType, '', queryParam, queryParamShow);
              },
            });
          }
        },
      },
      {
        permissionId: '6063',
        text: '库存转换',
        handleClick: () => {
          const rows: MainItem[] = context.mainSubStructureModel.gridModel.selectRows;
          if (rows.length === 0) {
            const error = '请至少选择一条数据';
            message.error({
              key: error,
              content: error,
            });
            return;
          }

          if (!rows.every((item) => [
            `${ENUM_OWNER.replaceDeliverOwnerType}`,
            `${ENUM_OWNER.selfStockType}`,
          ].includes(`${item.stock_type_value}`))) {
            const error = '请选择同一个代发货主';
            message.error({
              key: error,
              content: error,
            });
            return;
          }

          renderModal(
            <GoodsExchangeOwnerModal
              callback={((params) => {
                return request({
                  url: '/api/cloud/wms/rest/bill/other/goods/property/trans',
                  method: 'post',
                  data: {
                    ...params,
                    rows: undefined,
                    details: params.rows.map((item) => ({
                      ownerId: item.owner_id,
                      skuId: item.sku_id,
                      productId: item.product_id,
                      warehouseAreaType: item.warehouse_area_type,
                      warehouseAreaId: item.warehouse_area_id,
                      stockType: item.stock_type_value,
                      number: item._number,
                    })),
                  },
                  headers: { warehouseId: context.normalProgramme.filterItems.params['warehouse_id-4-6'] || '' },
                })
                  .then(() => {
                    message.success('转移成功');
                    context.mainSubStructureModel.gridModel.clearToOriginal();
                    context.mainSubStructureModel.onRefresh();
                    destroyAllModal();
                  });
              })}
              onCancel={destroyAllModal}
              ownerData={context.normalProgramme.filterItems.dict['owner_id-4-6'] || []}
              rows={rows}
            />
          );
        },
      },
      {
        text: '补货',
        permissionId: '4203',
        handleClick: () => {
          const selectRow = context.mainSubStructureModel.gridModel.selectRows;
          if (selectRow.length === 0) {
            const error = '请至少选择一条数据';
            message.error({
              key: error,
              content: error,
            });
          }

          if (selectRow.some((item) => !item.reserve_stock_num)) {
            const error = '仅可勾选拣货区且备货区有库存的商品进行补货';
            message.error({
              key: error,
              content: error,
            });
            return;
          }

          Modal.confirm({
            content: '确定补货吗?',
            onOk: async() => {
              await request({
                url: '/api/cloud/wms/rest/replenish/order/restockForManual',
                method: 'post',
                data: { stockId: selectRow.map((item) => item.v_stock_id).join(',') },
              });
            },
          });
        },
      },
    ],
  });
}
