import type { FilterSelect } from 'egenie-utils';
import { NormalProgramme } from 'egenie-utils';
import { getWarehouseArea } from '../../utils';
import type { Store } from './index';

export function normalProgramme(context: Store): NormalProgramme {
  return new NormalProgramme({
    filterItems: [
      {
        type: 'select',
        field: 'warehouse_id-4-6',
        label: '仓库',
        allowClear: false,
        onChangeCallback: (warehouseId: string) => {
          const warehouseAreaIdItem = context.normalProgramme.filterItems.getFilterItem('warehouse_area_id-4-6') as FilterSelect;
          warehouseAreaIdItem.value = [];
          warehouseAreaIdItem.handleData([]);

          getWarehouseArea({ warehouseId })
            .then((data) => warehouseAreaIdItem.handleData(data));

          context.getDataAfterWarehouse(warehouseId);
          context.normalProgramme.filterItems.updateFilterItem([
            {
              field: 'owner_id-4-6',
              value: undefined,
            },
          ]);
        },
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'warehouse_area_id-4-6',
        label: '库区',
      },
      {
        type: 'input',
        field: 'product_no-1-4',
        label: '商品编码',
      },
      {
        type: 'input',
        field: 'product_name-1-4',
        label: '商品名称',
      },
      {
        type: 'input',
        field: 'seller_outer_no-1-4',
        label: '商品货号',
      },
      {
        type: 'input',
        field: 'sku_no-1-2',
        label: 'SKU编码',
      },
      {
        type: 'input',
        field: 'bar_code-1-2',
        label: '条形码',
      },
      {
        type: 'inputNumberGroup',
        field: 'real_number-2-99-5',
        label: '实物库存',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'owner_id-4-6',
        label: '货主',
      },
      {
        type: 'select',
        mode: 'multiple',
        field: 'vendor_tenant_id-4-6',
        label: '供应商',
        maxItemsLength: 500,
      },
      {
        type: 'select',
        field: 'un_sale_only',
        label: '是否滞销',
        data: [
          {
            value: '1',
            label: '是',
          },
          {
            value: '0',
            label: '否',
          },
        ],
      },
      {
        type: 'select',
        field: 'ownerStockTypeMapEnum',
        label: '库存类型',
        mode: 'multiple',
      },
    ],
    count: 6,
    handleSearch: () => context.mainSubStructureModel.onQuery(),
  });
}
