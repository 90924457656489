import { Radio, Form, Modal, Select, InputNumber } from 'antd';
import { formatNumber } from 'egenie-common';
import type { ValueAndLabelData } from 'egenie-utils';
import { MainSubStructure, ImgFormatter, MainSubStructureModel } from 'egenie-utils';
import { Observer, observer } from 'mobx-react';
import React from 'react';
import { ENUM_OWNER } from '../../utils';
import type { MainItem } from './types';

const TRANSFER_TYPE = {
  owner: {
    value: '1',
    label: '货主转移',
  },
  supplier: {
    value: '2',
    label: '供应商转移',
  },
};

interface CallbackParams {
  transType: string;
  destOwnerId: string;
  rows: MainItem[];
}

interface GoodsExchangeOwnerModalProps {
  ownerData: ValueAndLabelData;
  callback: (params: CallbackParams) => Promise<unknown>;
  onCancel: (...args: any[]) => any;
  rows: MainItem[];
}

const mainSubStructureModel = new MainSubStructureModel({
  grid: {
    columns: [
      {
        key: 'pic',
        name: '图片',
        width: 90,
        formatter: ({ row }) => <ImgFormatter value={row.pic}/>,
      },
      {
        key: 'sku_no',
        name: 'SKU编码',
        width: 250,
      },
      {
        key: 'vendor_name',
        name: '供应商',
        width: 150,
      },
      {
        key: 'owner_name',
        name: '当前货主',
        width: 150,
      },
      {
        name: '数量',
        key: '_number',
        width: 200,
        formatter: ({
          rowIdx,
          row,
        }) => {
          return (
            <Observer>
              {
                () => (
                  <InputNumber
                    max={formatNumber(row.real_number)}
                    min={0}
                    onChange={(value) => mainSubStructureModel.gridModel.rows[rowIdx]._number = formatNumber(value)}
                    onClick={(event) => event.stopPropagation()}
                    size="small"
                    value={mainSubStructureModel.gridModel.rows[rowIdx]._number}
                  />
                )
              }
            </Observer>
          );
        },
      },
    ].map((info) => ({
      resizable: true,
      sortable: false,
      ...info,
    })),
    rows: [],
    primaryKeyField: 'v_stock_id',
    showPager: false,
    sortByLocal: false,
    showCheckBox: false,
    showEmpty: false,
  },

  api: { onQuery: (params) => Promise.reject() },
  hiddenSubTable: true,
});

export const GoodsExchangeOwnerModal = observer(({
  ownerData,
  callback,
  onCancel,
  rows,
}: GoodsExchangeOwnerModalProps) => {
  React.useEffect(() => {
    mainSubStructureModel.gridModel.clearToOriginal();
    mainSubStructureModel.gridModel.rows = rows.map((item) => ({
      ...item,
      _number: formatNumber(item.real_number),
    }));
  }, rows);

  const [form] = Form.useForm();
  const [
    paramData,
    setParamData,
  ] = React.useState<{ loading: boolean; params: CallbackParams; }>({
    loading: false,
    params: null,
  });

  const [
    transType,
    setTransType,
  ] = React.useState<string>(undefined);

  const handleFinish = React.useCallback((params) => {
    setParamData({
      loading: true,
      params: {
        ...params,
        rows: mainSubStructureModel.gridModel.rows,
      },
    });
  }, []);

  React.useEffect(() => {
    if (paramData.params) {
      callback(paramData.params)
        .catch(() => setParamData({
          loading: false,
          params: null,
        }));
    }
  }, [
    callback,
    paramData,
  ]);

  return (
    <Modal
      centered
      maskClosable={false}
      okButtonProps={{ loading: paramData.loading }}
      onCancel={() => onCancel()}
      onOk={() => form.submit()}
      title="转移货权"
      visible
      width={1000}
    >
      <Form
        form={form}
        initialValues={{}}
        labelCol={{ span: 6 }}
        layout="horizontal"
        onFinish={handleFinish}
        wrapperCol={{ span: 18 }}
      >
        <Form.Item
          label="转移类型"
          name="transType"
          rules={[
            {
              required: true,
              message: '请选择转移类型',
            },
          ]}
        >
          <Radio.Group
            onChange={(event) => setTransType(event.target.value)}
            options={Object.values(TRANSFER_TYPE)}
          />
        </Form.Item>
        {
          transType === TRANSFER_TYPE.owner.value ? (
            <Form.Item
              label="目标货主"
              name="destOwnerId"
              rules={[
                {
                  required: true,
                  message: '请选择目标货主',
                },
              ]}
            >
              <Select
                optionFilterProp="label"
                options={ownerData.filter((item) => `${item.ownerType}` === `${ENUM_OWNER.replaceDeliverOwnerType}` || item.value === `${ENUM_OWNER.chooseStyleOwnerId}`)}
                placeholder="请选择目标货主"
                showSearch
              />
            </Form.Item>
          ) : null
        }
      </Form>
      <div style={{ height: 300 }}>
        <MainSubStructure store={mainSubStructureModel}/>
      </div>
    </Modal>
  );
});
